import React from "react";
import { Header } from "components/globals/Header";
import { NextPage } from "next";
import Image from "next/image";
import bg404 from "assets/images/404.png";
import { Container } from "components/Container";
import { BlockFullScreenContainer } from "components/blocks/BlockFullScreenContainer";

const FourOhFourPage: NextPage = () => {
  return (
    <BlockFullScreenContainer className="bg-tertiary-600">
      <Header
        title="Dead end"
        description="Turn back! You've reached the forbidden zone!"
      />
      <Container className="flex h-full items-center justify-center p-8 lg:p-32">
        <Image src={bg404} alt="404 image" draggable={false} />
      </Container>
    </BlockFullScreenContainer>
  );
};

export default FourOhFourPage;
